@charset "UTF-8";
@import "./reset.scss";
@import "./variables.scss";
@import "./home.scss";
@import "./divider.scss";
@import "./portfolio.scss";
@import "./modal.scss";
@import "./button.scss";
@import "./navbar.scss";

@import "./about.scss";
@import "./section.scss";
@import "./contact.scss";
@import "./toast.scss";
@import "./footer.scss";
@import "./largeScreens.scss";
*,
*::before,
*::after {
  box-sizing: border-box;
}
// acessibility check
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

