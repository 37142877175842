.modal-backdrop {
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-background {
    top: 10%;
    left: 10%;
    right: 10%;
    height: 80vh;
    background: $bs-secondary;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    margin: 0 auto;
    border-radius: 0.5rem;
}

.modal-container{
    width: 80vw;
    height: 80vh;
    overflow-y: scroll;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: flex;
    position: relative;
    z-index: 16;
    border-radius: 0.5rem;
}

.modal-content{
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 100%;
    margin: 1%;
    align-items: center;
    justify-content: space-evenly;
    line-height: 1.8;
    color: #141414;
    p {
      margin-bottom: 1rem;
    }

    .top-modal{
        justify-self: flex-start;
    }
    .modal-body{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: $font-montserrat;
    }
    .split{
        display: flex;
    }
    //Mobile and Tablet
    @media (min-width: 320px) and (max-width:1200px) {
        margin-top: 5rem;
        .modal-body{
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
        }

        .split{
            display: flex;
            flex-direction: column;
        }
        .modal-text{
            width: 90%;
            font-size: 1rem;
            margin: 0 auto;
        }
        .img-border{
            width: 100%;
            padding-bottom: 2%;
        }
        .top-modal{
            margin-top: 25%;
        }
    }

}
.modal-text{
    width: 50%;
    margin-right: 1rem;
    font-size: 1.2rem;
}
.modal-footer{
    display: flex;
    flex-flow: row nowrap;
    .link{
        border-radius: 100%;
        display: inline-flex;
        width: 3.25rem;
        height: 3.25rem;
        font-size: 1.25rem;
        justify-content: center;
        align-items: center;
        border: 0.125rem solid transparent;
        color: $bs-white;
        border-color: $bs-primary;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 1rem;
        &:hover{
            background-color: $bs-primary;
        }
        img{
            filter: invert(0%) sepia(0%) saturate(20%) hue-rotate(11deg) brightness(103%) contrast(105%);;
            width: 2rem;
        }
    }
}
.img-border{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    color: $bs-white;
    text-align: center;
    .techs{
        background-color: $bs-secondary;
        width: 80%;
        border-radius: 0px 0px 0.5rem 0.5rem;
    }   
    img{
        width: 100%;
        border-radius: 0.5rem;
        border: 1rem solid $bs-secondary;

    }
    a{
        text-decoration: none;
        color: $bs-white;
        &:hover{
            color: $bs-primary;
        }
    }
    h5{
        font-size: 1.1rem;
    }

}
.bullets::before{
    content: "\2022";  /* unicode for a bullet */
    color: $bs-primary; 
    font-size: 1.5rem;
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
}
.bullets{
    margin-left: 1rem;
}

.close-button {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
    padding: 0;
    z-index: 10;
    color: #d3d3d3;
    filter: invert(52%) sepia(1%) saturate(1%) hue-rotate(323deg) brightness(97%) contrast(92%);
    &:hover{
        filter: invert(19%) sepia(18%) saturate(350%) hue-rotate(169deg) brightness(99%) contrast(90%);
    }
}