.home-container{
    background-color: $bs-teal;
    color: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: calc(6rem + 100px);
    padding-bottom: 6rem;
}
.home-contents {
    max-width: 80%;
    font-family: $font-montserrat;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center ;
    .home-heading {
        font-size: 4rem;
        line-height: 3.5rem;
        text-transform: uppercase;
    }
    // Mobile
    @media (min-width: 320px) and (max-width: 1000px) {
        .home-heading{
            text-align: center;
        }
        .home-subheading{
            text-align: center;
        }
    }
}
.headshot {
    width: 13rem;
}
.brackets{
    width: 2rem;
}