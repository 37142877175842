
.divider-custom {
    margin: 1.25rem 0 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .right{
        margin-left: 1rem;
    }
    .left {
        margin-right: 1rem;
    }
  }
  .divider-dark .divider-custom-line{
    background-color: $bs-secondary  !important;
    border-color: $bs-secondary !important;
  }

.divider-custom .divider-custom-line {
  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: white;
  border-radius: 1rem;
  border-color: white;
}
.brackets-white{
  filter: invert(100%) sepia(94%) saturate(24%) hue-rotate(16deg) brightness(107%) contrast(107%);
}