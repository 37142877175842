   // Colors
$bs-indigo: #6610f2;
$bs-blue: #0d6efd;
$bs-purple: #6f42c1;
$bs-pink: #d63384;
$bs-red: #dc3545;
$bs-orange: #fd7e14;
$bs-yellow: #ffc107;
$bs-green: #198754;
$bs-teal: #1abc9c;
$bs-cyan: #0dcaf0;
$bs-white: #fff;
$bs-gray: #6c757d;
$bs-gray-dark: #343a40;
$bs-primary: #1abc9c;
$bs-secondary: #2c3e50;
$bs-footer: #1a252f;
$bs-success: #198754;
$bs-info: #0dcaf0;
$bs-warning: #ffc107;
$bs-danger: #dc3545;
$bs-light: #f8f9fa;
$bs-dark: #212529;

// Fonts
$font-sans-serif : "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-montserrat: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";