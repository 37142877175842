.projects{    
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.project{
    margin: 1.5rem 0.5rem 1.5rem 0.5rem;
    h3{
        text-align: center;
        font-size: 2rem;
        line-height: 2.5rem;
        color: $bs-secondary;
        font-family: $font-montserrat;
    }
    
}

.icons a  img{
    width: 5rem;
    filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(11deg) brightness(109%) contrast(101%);
    &:hover{
        filter: invert(23%) sepia(29%) saturate(549%) hue-rotate(168deg) brightness(93%) contrast(96%);
    }

}
.portfolio-item {
    cursor: pointer;
    position: relative;
    display: block;
    max-width: 25rem;
    border-radius: 0.5rem;
    overflow: hidden;
    
}
.item-caption {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background-color: rgba(26, 188, 156, 0.9);
    height: 100%;
    width: 100%;
    &:hover{
        opacity: 1;
    }
    .icon{
        width: 3rem;
        filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(11deg) brightness(109%) contrast(101%);
        &:hover{
            filter: invert(23%) sepia(29%) saturate(549%) hue-rotate(168deg) brightness(93%) contrast(96%);
        }

    }
}
.item-caption-content{
    font-size: 1.5rem;
    color: white;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .icons{
        display: flex;
        justify-content: space-around;
        width: 80%;
        margin-top: 20%;
        // Mobile
        @media (min-width: 320px) and (max-width: 1000px) {
            display: none;
        }
    }
    .click-modal{
        width: 100%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(11deg) brightness(109%) contrast(101%);
            width: 3rem;
        }
        // Mobile
        @media (min-width: 320px) and (max-width: 1000px) {
            height: 100%    ;
        }
        &:hover{
            background-color: $bs-secondary;
        }
    }
}
.bullets{
    margin-left: 1rem;
}
.img-fluid {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}