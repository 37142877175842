.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 0.125rem solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary {
    color: $bs-white;
    background-color: $bs-primary;
    border-color: $bs-primary;
    background-color: $bs-primary;
    &:hover{
        background-color: $bs-green;
        border-color: $bs-green;
    }
}
.button-icon{
    width: 1rem;
    margin-right: 0.5rem;
}
.xl{
    padding: 1rem 1.75rem;
    font-size: 1.25rem;
    align-self: flex-start;
}
.l {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    align-self: flex-start;
}
.btn-svg{
    filter: invert(94%) sepia(94%) saturate(25%) hue-rotate(6deg) brightness(106%) contrast(106%);
    width: 1rem;
    margin-left: 0.5rem;
}