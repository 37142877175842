.nav-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $bs-secondary;
    text-transform: uppercase;
    transition: padding-top 0.3s, padding-bottom 0.3s;

    // fix to top of page
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    // Mobile
    @media (max-width: 320px) {
        width: 100%;
        padding: 0em;
        .navbar-nav {
            flex-direction: column;
        }
    }


}
.navbar-shrink {
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: padding-top 0.3s, padding-bottom 0.3s;
}
.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: $font-montserrat;
    padding-left: 5rem;
    padding-right: 5rem;
    font-weight: 700;
    .name{
        text-decoration: none;
        font-size: 1.75em;
        color: white;

    }
    // Mobile
    @media (min-width: 320px) and (max-width: 1000px) {
        flex-direction: column;
        padding: 0em;
        .navbar-nav {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
        }
        .navbar-active{
            display: block;
        }
        .active {
            color: $bs-primary;
            background-color: $bs-secondary;
            &:hover {
            color: inherit;
            background-color: inherit;
            }
        }
        .nav-item{
            justify-content: center;
        }
        .name-container {
            margin-top: 1rem;
            margin-bottom: 1rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .name{
                font-size: 1.5rem;
            }
            .mobile-menu-button{
                display: block;
            }
        }

    }
}
// Mobile nav animation
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.mobile-menu-button{
    display: none;
}
.navbar-nav {
    display: flex;
    margin-bottom: 0;

    a {
        text-decoration: none;
        color: white;
        &:hover{
            color: $bs-teal;
        }
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 1.1rem;
    }
    .nav-item{
        display: flex;
        align-items: center;
        margin-left: 0.25rem;
        margin-right: 0.25rem;

    }
}
#resume{
    margin-left: 1rem;
    text-transform: uppercase;
}
.nav-link{
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
}
.active{
    color: $bs-white;
    background-color: $bs-primary;
    &:hover{
        color: $bs-primary;
        background-color: $bs-white;
    }
}