.page-section {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.section-heading {
    font-size: 3rem;
    line-height: 2.5rem;
    color: $bs-secondary;
    text-transform: uppercase;
    font-family: $font-montserrat;
    font-weight: 700;
    text-align: center;
}
.text-white {
    color: $bs-white !important;
}