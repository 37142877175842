.about {
    background-color: $bs-primary;
    color: $bs-white;
    p {
        font-size: 1.5rem;
        font-family: $font-montserrat;
        margin-left: 10%;
        margin-right: 10%;
    }

    // Mobile
    @media (max-width: 320px) {
        p{
            font-size: 1.2rem;
            margin: 0.5rem;
        }
    }
}